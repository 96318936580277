import { update } from 'lodash'
import { Box } from '@mui/material'

export const ItaContent = () => (
  <Box sx={{ p: ['24px', '32px'] }}>
    <h2 style={{ textAlign: 'center' }}>Termini di Utilizzo per il Sito Web di Imile Italia</h2>
    <p style={{ textAlign: 'center' }}>Ultimo aggiornamento: 19 aprile 2024</p>
    <p>
      Questi Termini di Utilizzo (di seguito denominati Termini) si applicano personalmente a te e
      al tuo utilizzo del Sito Web di iMile Italia su{' '}
      <a href='https://www.imile.com/it/'>https://www.imile.com/it/</a> (di seguito denominato il
      Sito Web). Questo sito web è creato e gestito da iMile Italy s.r.l., una società incorporata
      in Italia, con sede legale in Via Giosuè Carducci, 15 20123 Milano, e numero di registrazione
      13027090961 (di seguito denominata iMile)
    </p>
    <p>
      Utilizzando il Sito Web, accetti questi Termini. Il tuo utilizzo del Sito Web e delle
      funzionalità messe a tua disposizione all'interno del Sito Web saranno ammissibili solo previa
      accettazione dei Termini. SE NON SEI D'ACCORDO CON UNA QUALSIASI PARTE DEI TERMINI, TI
      PREGHIAMO DI NON UTILIZZARE IL SITO WEB.
    </p>
    <p>
      iMile si riserva il diritto di cambiare, modificare, aggiungere o rimuovere porzioni di questi
      Termini in qualsiasi momento.
    </p>
    <p>
      Accetti che iMile possa, a sua esclusiva discrezione e senza preavviso, terminare il tuo
      accesso al Sito Web e/o bloccare il tuo futuro accesso al Sito Web, se iMile determina che hai
      violato questi Termini o altri accordi o linee guida associati al tuo utilizzo del Sito Web.
    </p>
    <h3>Contenuto Software</h3>
    <p>
      iMile o i suoi licenzianti possiedono e conservano tutti i diritti, compresi tutti i diritti
      di proprietà intellettuale applicabili, su tutto il software presente su questo Sito Web, sia
      in forma sorgente che binaria, codice di esempio, API, SDK, documentazione associata e altri
      materiali correlati (collettivamente "Contenuto Software").
    </p>
    <p>
      A meno che tu non abbia ottenuto una licenza rilevante sulla base di un altro accordo
      stipulato tra te e iMile, nulla in questi Termini concede alcun diritto o licenza sul
      Contenuto Software a te, e non dovrai eseguire reverse engineering, decompilare,
      disassemblare, dividere, adattare, impiantare o implementare altri lavori derivati sul
      Contenuto Software.
    </p>
    <h3>Marchi registrati</h3>
    <p>
      Tutti i marchi e i loghi visualizzati, menzionati o altrimenti utilizzati in questo Sito Web
      sono di proprietà di iMile o dei suoi licenzianti, come indicato, se applicabile. Non ti è
      permesso utilizzare in alcun modo questi marchi o loghi senza esplicita autorizzazione scritta
      preventiva da parte di iMile o dei relativi licenzianti, se applicabile.
    </p>
    <h3>Privacy</h3>
    <p>
      iMile utilizzerà solo i tuoi dati personali in conformità con la Politica sulla Privacy di
      iMile.
    </p>
    <h3>Proprietà intellettuale</h3>
    <p>
      Tutti i contenuti presenti su questo Sito Web sono protetti da copyright da iMile o dai
      licenzianti applicabili, salvo diversa indicazione. Senza il preventivo consenso scritto di
      iMile o di altre parti correlate, qualsiasi contenuto sul Sito Web non deve essere riprodotto,
      modificato, promosso, distribuito, fotocopiato, visualizzato, smontato, sottoposto a reverse
      engineering, decompilato, collegato o trasmesso con collegamenti ipertestuali, caricato su
      altri server con metodo "mirroring", archiviato in un sistema di recupero delle informazioni o
      utilizzato in qualsiasi altro modo da parte di chiunque e con qualsiasi mezzo.
    </p>
    <h3>Contenuto del Sito Web</h3>
    <p>
      I Contenuti del Sito Web includono, ma non si limitano ai testi, immagini, dati, pagine web e
      collegamenti offerti sul Sito Web o attraverso di esso. Questo Sito Web e i suoi contenuti
      sono forniti solo per la tua convenienza. Sebbene iMile abbia cercato di fornire informazioni
      accurate su questo Sito Web, non si assume alcun obbligo o responsabilità per l'accuratezza e
      la completezza delle informazioni. I Contenuti del Sito Web possono contenere inesattezze o
      errori tipografici involontari. Questi saranno corretti a discrezione di iMile, man mano che
      vengono scoperti.
    </p>
    <h3>Collegamenti a siti terzi</h3>
    <p>
      Sebbene siano presenti collegamenti a siti web di terze parti su questo Sito Web per la tua
      convenienza, iMile non sarà responsabile per i contenuti di tali siti web. Potrebbe essere
      necessario esaminare e accettare le regole di utilizzo applicabili quando si utilizzano tali
      siti web. Inoltre, un collegamento a un sito web di terze parti non implica che iMile approvi
      il sito o i prodotti o servizi ivi menzionati.
    </p>
    <h3>Virus</h3>
    <p>
      iMile compie tutti gli sforzi ragionevoli per escludere virus da queste pagine web, ma non può
      garantire tale esclusione e declina ogni responsabilità per virus. Si prega di adottare tutte
      le precauzioni appropriate prima di scaricare informazioni da queste pagine web.
    </p>
    <h3>Esclusione di garanzie</h3>
    <p>
      Tutte le informazioni fornite su questo Sito Web sono fornite "così come sono", senza garanzie
      o rappresentazioni di alcun tipo. iMile dichiara espressamente, nella massima misura
      consentita dalla legge, di declinare tutte le garanzie, esplicite, implicite, statutarie o di
      altro tipo, comprese ma non limitate alle garanzie implicite di commerciabilità, idoneità per
      uno scopo particolare o non violazione dei diritti di terzi.
    </p>
    <h3>Limitazione di Responsabilità</h3>
    <p>
      Nella misura consentita dalla legge, né iMile né alcuna delle sue società affiliate o
      controllate, direttori, agenti, dipendenti o altri rappresentanti saranno responsabili per
      danni diretti, indiretti, speciali, incidentali, consequenziali, punitivi e/o esemplari,
      compresi, a titolo esemplificativo ma non esaustivo, la perdita di profitti o ricavi, la
      perdita di dati e/o la perdita di attività, in relazione a questo Sito Web o all'uso o
      all'incapacità di utilizzare questo Sito Web o alla fiducia nei contenuti in esso, anche se
      iMile è stato informato della possibilità di tali danni. Salvo diversamente concordato per
      iscritto da iMile in un contratto applicabile, nella misura consentita dalla legge
      applicabile, iMile non sarà responsabile per i materiali di questo Sito Web, indipendentemente
      dalla ragione della responsabilità o dalla teoria del delitto su cui si basa.
    </p>
    <h3>Divulgazione delle informazioni</h3>
    <p>
      Tutte le informazioni fornite a iMile dai visitatori di queste pagine web sono considerate
      riservate e non saranno divulgate da iMile a terzi, tranne per quanto possa essere richiesto
      per la fornitura dei servizi.
    </p>
  </Box>
)

export const AusContent = () => (
  <Box sx={{ p: ['24px', '32px'] }}>
    <h2 style={{ textAlign: 'center' }}>Terms of Use for iMile Australia Website</h2>
    <p style={{ textAlign: 'center' }}>Last Update: April 30, 2024</p>
    <p>
      These Terms of Use (hereinafter as the Terms) apply to you personally and your use of the
      iMile Australia Website at <a href='https://www.imile.com/'>https://www.imile.com/</a>{' '}
      (hereinafter as the Website). This website is created and maintained by iMile
      Delivery&Logistic Pty Ltd, a company incorporated in Australia whose registered address is at
      Warehouse 11B' Office 11B 9-11 Ferndell Street South Granville NSW, South Granville, NSW 2142
      and whose registered number is ABN 57664403785 (hereinafter as iMile).
    </p>
    <p>
      By using the Website, you agree to these Terms. Your use of the Website and the
      functionalities provided to you within the Website shall only be admissible subject to your
      prior acceptance of the Terms. IF YOU DO NOT AGREE WITH ANY OF THE TERMS, PLEASE DO NOT USE
      THE WEBSITE.
    </p>
    <p>
      iMile reserves the right to change, modify, add or remove portions of these Terms at any time.
    </p>
    <p>
      You agree that iMile may, at its sole discretion and without prior notice to you, terminate
      your access to the Website and/or block your future access to the Website, if iMile determines
      that you have violated these Terms or other agreements or guidelines which may be associated
      with your use of the Website.
    </p>
    <h3>Software Content</h3>
    <p>
      iMile or its licensors own and retain all rights, including all applicable intellectual
      property rights, in all software on this Website, in both source and binary forms, sample
      code, APIs, SDKs, associated documentation, and other related materials (collectively,
      "Software Content").
    </p>
    <p>
      Unless you have obtained a relevant license through other agreement entered between you and
      iMile, nothing in these Terms grants any right or license to Software Content to you, and you
      shall not reverse engineer, decompile, disassemble, split, adapt, implant, or implement other
      derivative works on the Software Content.
    </p>
    <h3>Trademarks</h3>
    <p>
      All trademarks and logos displayed, mentioned or otherwise used in this Website are property
      of iMile or its licensors as stated if applicable. You are not permitted to use any of these
      trademarks or logos in any way without explicit prior written permission from iMile or such
      licensors as applicable.
    </p>
    <h3>Privacy</h3>
    <p>iMile will only use your personal information in accordance with iMile's Privacy Policy.</p>
    <h3>Copyright</h3>
    <p>
      All contents on this Website are copyrighted by iMile or applicable licensors unless otherwise
      stated. Without prior written consent of iMile or other related parties, any content on the
      Website shall not be reproduced, modified, promoted, distributed, photocopied, played,
      disassembled, reverse engineered, decompiled, linked or transmitted with hyperlinks, loaded
      into other servers in "mirroring method", stored in information retrieval system, or otherwise
      used for any other purpose by any person by any means.
    </p>
    <h3>Website Content</h3>
    <p>
      The Website Contents include but are not limited to text, images, data, web pages, and links
      offered on or through the Website. This Website and its contents are provided for your
      convenience only. Although iMile has attempted to provide accurate information on this
      Website, it assumes no obligation or responsibility whatsoever regarding the accuracy and
      completeness of the information. The Website Contents may contain inadvertent inaccuracies or
      typographical errors. These will be corrected at iMile's discretion, as they are found.
    </p>
    <h3>Links to Third Parties</h3>
    <p>
      Although links to third party websites may be contained in this Website for your convenience,
      iMile shall not be responsible for any content of any such websites. You might need to review
      and agree to applicable rules of use when using such websites. In addition, a link to
      third-party website does not imply that iMile endorses the site or the products or services
      referenced therein.
    </p>
    <h3>Viruses</h3>
    <p>
      iMile makes all reasonable attempts to exclude viruses from these web pages, but it cannot
      ensure this exclusion, and no liability is accepted for viruses. Please take all appropriate
      safeguards before downloading information from these web pages.
    </p>
    <h3>Disclaimer of Warranties</h3>
    <p>
      All information provided on this website is provided on an "as is" basis without warranties,
      guarantees or representations of any kind. iMile hereby expressly disclaims, to the fullest
      extent permitted by law, all express, implied, statutory or other warranties, guarantees or
      representations, including but not limited to, the implied warranties of merchantability,
      fitness for a particular purpose, or non-infringement of third-party rights.
    </p>
    <h3>Limitation of Liability</h3>
    <p>
      To the extent permitted by law, neither iMile nor any of its affiliates, subsidiaries,
      directors, agents, employees or other representatives shall be liable for any direct,
      indirect, special, incidental, consequential, punitive, and/or exemplary damages including
      without limitation, loss of profits or revenues, loss of data, and/or loss of business, in
      connection with this Website or the use of or inability to use this Website or reliance on the
      contents contained herein, even if iMile is advised of the possibility of such damages. Unless
      otherwise agreed in writing by iMile in any applicable contract, to the extent permitted by
      the applicable law, iMile shall not be liable for the materials of this Website, regardless of
      the reason for the liability or the tort theory it is based on.
    </p>
    <h3>Disclosure of Information</h3>
    <p>
      All information provided to iMile by visitors to these web pages is considered to be
      confidential and will not be disclosed by iMile to any third party except as may be required
      for the provision of the services.
    </p>
  </Box>
)

export const enContent = () => (
  <Box sx={{ p: ['24px', '32px'] }}>
    <h2 style={{ textAlign: 'center' }}>Terms of Use for iMile Italy Website</h2>
    <p style={{ textAlign: 'center' }}>Last Update: April 19, 2024</p>
    <p>
      These Terms of Use (hereinafter as the Terms) apply to you personally and your use of the
      iMile Italy Website at <a href='https://www.imile.com/'>https://www.imile.com/</a>{' '}
      (hereinafter as the Website). This website is created and maintained by iMile Italy s.r.l., a
      company incorporated in Italy whose registered address is at Via Giosuè Carducci 15, 20123
      Milano, Italy and whose registered number is 13027090961 (hereinafter as iMile).
    </p>
    <p>
      By using the Website, you agree to these Terms. Your use of the Website and the
      functionalities provided to you within the Website shall only be admissible subject to your
      prior acceptance of the Terms. IF YOU DO NOT AGREE WITH ANY OF THE TERMS, PLEASE DO NOT USE
      THE WEBSITE.
    </p>
    <p>
      iMile reserves the right to change, modify, add or remove portions of these Terms at any time.
    </p>
    <p>
      You agree that iMile may, at its sole discretion and without prior notice to you, terminate
      your access to the Website and/or block your future access to the Website, if iMile determines
      that you have violated these Terms or other agreements or guidelines which may be associated
      with your use of the Website.
    </p>
    <h3>Software Content</h3>
    <p>
      iMile or its licensors own and retain all rights, including all applicable intellectual
      property rights, in all software on this Website, in both source and binary forms, sample
      code, APIs, SDKs, associated documentation, and other related materials (collectively,
      "Software Content").
    </p>
    <p>
      Unless you have obtained a relevant license through other agreement entered between you and
      iMile, nothing in these Terms grants any right or license to Software Content to you, and you
      shall not reverse engineer, decompile, disassemble, split, adapt, implant, or implement other
      derivative works on the Software Content.
    </p>
    <h3>Trademarks</h3>
    <p>
      All trademarks and logos displayed, mentioned or otherwise used in this Website are property
      of iMile or its licensors as stated if applicable. You are not permitted to use any of these
      trademarks or logos in any way without explicit prior written permission from iMile or such
      licensors as applicable.
    </p>
    <h3>Privacy</h3>
    <p>iMile will only use your personal data in accordance with iMile's Privacy Policy.</p>
    <h3>Copyright</h3>
    <p>
      All contents on this Website are copyrighted by iMile or applicable licensors unless otherwise
      stated. Without prior written consent of iMile or other related parties, any content on the
      Website shall not be reproduced, modified, promoted, distributed, photocopied, played,
      disassembled, reverse engineered, decompiled, linked or transmitted with hyperlinks, loaded
      into other servers in "mirroring method", stored in information retrieval system, or otherwise
      used for any other purpose by any person by any means.
    </p>
    <h3>Website Content</h3>
    <p>
      The Website Contents include but are not limited to text, images, data, web pages, and links
      offered on or through the Website. This Website and its contents are provided for your
      convenience only. Although iMile has attempted to provide accurate information on this
      Website, it assumes no obligation or responsibility whatsoever regarding the accuracy and
      completeness of the information. The Website Contents may contain inadvertent inaccuracies or
      typographical errors. These will be corrected at iMile's discretion, as they are found.
    </p>
    <h3>Links to Third Parties</h3>
    <p>
      Although links to third party websites may be contained in this Website for your convenience,
      iMile shall not be responsible for any content of any such websites. You might need to review
      and agree to applicable rules of use when using such websites. In addition, a link to
      third-party website does not imply that iMile endorses the site or the products or services
      referenced therein.
    </p>
    <h3>Viruses</h3>
    <p>
      iMile makes all reasonable attempts to exclude viruses from these web pages, but it cannot
      ensure this exclusion, and no liability is accepted for viruses. Please take all appropriate
      safeguards before downloading information from these web pages.
    </p>
    <h3>Disclaimer of Warranties</h3>
    <p>
      All information provided on this website is provided on an "as is" basis without warranties,
      guarantees or representations of any kind. iMile hereby expressly disclaims, to the fullest
      extent permitted by law, all express, implied, statutory or other warranties, guarantees or
      representations, including but not limited to, the implied warranties of merchantability,
      fitness for a particular purpose, or non-infringement of third-party rights.
    </p>
    <h3>Limitation of Liability</h3>
    <p>
      To the extent permitted by law, neither iMile nor any of its affiliates, subsidiaries,
      directors, agents, employees or other representatives shall be liable for any direct,
      indirect, special, incidental, consequential, punitive, and/or exemplary damages including
      without limitation, loss of profits or revenues, loss of data, and/or loss of business, in
      connection with this Website or the use of or inability to use this Website or reliance on the
      contents contained herein, even if iMile is advised of the possibility of such damages. Unless
      otherwise agreed in writing by iMile in any applicable contract, to the extent permitted by
      the applicable law, iMile shall not be liable for the materials of this Website, regardless of
      the reason for the liability or the tort theory it is based on.
    </p>
    <h3>Disclosure of Information</h3>
    <p>
      All information provided to iMile by visitors to these web pages is considered to be
      confidential and will not be disclosed by iMile to any third party except as may be required
      for the provision of the services.
    </p>
  </Box>
)

export const plContent = () => (
  <Box sx={{ p: ['24px', '32px'] }}>
    <h2 style={{ textAlign: 'center' }}>Regulamin korzystania ze strony internetowej iMile</h2>
    <p style={{ textAlign: 'center' }}>Ostatnia aktualizacja: 8 lipca 2024</p>
    <p>
      Niniejszy Regulamin korzystania ze strony internetowej (dalej jako: Regulamin) określa warunki
      korzystania ze strony internetowej iMile Polska (dalej jako: iMile) pod adresem
      <a href='https://www.imile.com/pl/'>https://www.imile.com/pl/</a> (zwanej dalej Stroną), w tym
      z zawartych w niej treści i świadczonych w za jej pośrednictwem usług.
    </p>
    <p>
      Regulamin został wydany przez Imile logistics and transportation z siedzibą w Bielanach
      Wrocławskich, ul. Magazynowa 8, DC 5, 55-040 Bielany Wrocławskie, Wpisaną do Rejestru
      Przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla M.St. Warszawy w Warszawie,
      XIV Wydział Gospodarczy, pod nr KRS 0001008750 (zwaną dalej iMile).
    </p>
    <p>
      Korzystanie ze Strony oraz jej funkcjonalności jest równoznaczne z wyrażeniem zgody na objęcie
      warunkami niniejszego Regulaminu. JEŚLI NIE ZGADZASZ SIĘ Z JAKIMIKOLWIEK WARUNKAMI NINIEJSZEGO
      REGULAMINU, PROSIMY NIE KORZYSTAĆ ZE STRONY.
    </p>
    <p>
      iMile zastrzega sobie prawo do zmiany, modyfikacji, dodawania lub usuwania części Regulaminu w
      dowolnym momencie. iMile może w każdym momencie zmienić zakres lub rodzaj treści dostępnych na
      Stronie, a także rozszerzyć, zmienić, ograniczyć lub zaprzestać oferowania niektórych
      funkcjonalności, jak i całości narzędzi lub usług dostępnych na Stronie.
    </p>
    <h3>Oprogramowanie</h3>
    <p>
      iMile lub jej licencjodawcy są właścicielami i zachowują wszelkie prawa do całego
      oprogramowania na Stronie, zarówno w formie kodu źródłowego, jak i binarnego, przykładowych
      kodów, interfejsu programowania aplikacji (API), SDK, powiązanej dokumentacji oraz innych
      pokrewnych materiałów (łącznie „Oprogramowanie”).
    </p>
    <p>
      O ile nie uzyskałeś odpowiedniej licencji na podstawie odrębnej umowy zawartej pomiędzy Tobą a
      iMile, żaden zapis niniejszego Regulaminu nie przyznaje Ci jakichkolwiek praw ani licencji do
      Oprogramowania i nie możesz odtwarzać kodu źródłowego, dekompilować, demontować, dzielić,
      adaptować, wdrażać ani tworzyć innych dzieł pochodnych opartych na Oprogramowaniu.
    </p>
    <h3>Pliki Cookies</h3>
    <p>
      W trakcie korzystania ze Strony w systemie teleinformatycznym użytkownika instalowane są
      ciasteczka (pliki cookies) dla celów identyfikacji oraz dla celów statystycznych. Więcej
      informacji na temat plików cookies używanych przez iMile dostępnych jest w Polityce
      Prywatności oraz w Polityce Plików Cookie dostępnych pod adresem.
    </p>
    <h3>Znaki towarowe</h3>
    <p>
      Wszystkie znaki towarowe i logotypy wyświetlane, wspomniane lub w inny sposób używane na
      Stronie są własnością iMile lub jej licencjodawców. Zabrania się wykorzystywania tych znaków
      towarowych oraz logotypów w jakikolwiek sposób bez uprzedniej wyraźnej pisemnej zgody iMile
      lub jej licencjodawców.
    </p>
    <h3>Prywatność</h3>
    <p>
      iMile będzie wykorzystywać Twoje dane osobowe wyłącznie w sposób i w celu określonym w
      Polityce Prywatności.
    </p>
    <h3>Prawa autorskie</h3>
    <p>
      Wszystkie treści na niniejszej Stronie są chronione prawami autorskimi iMile lub jej
      licencjodawców, chyba że wyraźnie wskazano inaczej w niniejszym Regulaminie. Użytkownicy
      Strony mają prawo korzystania z materiałów udostępnianych za jej pośrednictwem wyłącznie w
      zakresie tzw. dozwolonego użytku, regulowanego przepisami ustawy o prawie autorskim i prawach
      pokrewnych.
    </p>
    <h3>Zawartość Strony</h3>
    <p>
      Zawartość Strony internetowej obejmuje, lecz nie ogranicza się do tekstu, obrazów, danych,
      oraz linków. Pomimo dochowania najwyższych starań, by zapewnić dokładność informacji
      prezentowanych na Stronie, iMile nie ponosi odpowiedzialności za dokładność i kompletność
      znajdujących się na niej informacji. Zawartość Strony może zawierać przypadkowe nieścisłości
      lub błędy drukarskie, które będą korygowane według uznania iMile, gdy zostaną zauważone. iMile
      nie ponosi odpowiedzialności za niedostępność danych w jakimkolwiek momencie, w tym za skutki
      wynikające z polegania na tych treściach.
    </p>
    <h3>Łącza do stron trzecich</h3>
    <p>
      Chociaż na niniejszej Stronie mogą znajdować się łącza do stron internetowych osób trzecich,
      iMile nie ponosi odpowiedzialności za treść takich stron. Przy korzystaniu z tych stron możesz
      być zobowiązany do zapoznania się i akceptacji stosownych zasad użytkowania. Ponadto
      umieszczenie odnośnika do strony trzeciej nie oznacza, że iMile popiera tę stronę ani
      produkty, czy usługi oferowane w jej treści.
    </p>
    <h3>Wirusy</h3>
    <p>
      All iMile podejmuje wszelkie rozsądne działania w celu wykluczenia wirusów, jednak przy
      korzystaniu ze Strony zalecane jest posiadanie zaktualizowanego oprogramowania antywirusowego.
    </p>
    <h3>Ograniczenie odpowiedzialności</h3>
    <p>
      iMile nie ponosi odpowiedzialności za prawne, finansowe i jakiekolwiek inne konsekwencje
      użycia przez użytkownika informacji zamieszczonych na Stronie. iMile nie odpowiada za szkody
      lub straty użytkownika lub osób trzecich związane z wykorzystaniem tych informacji.
      Korzystanie przez użytkownika ze Strony odbywa się na jego własne ryzyko, a dane i informacje
      zawarte na Stronie są publikowane wyłącznie do celów informacyjnych.
    </p>
    <h3>Ujawnianie informacji</h3>
    <p>
      Wszystkie informacje przekazane iMile przez odwiedzających Stronę są uważane za poufne i nie
      będą ujawniane przez iMile żadnej osobie trzeciej, chyba że będzie to konieczne do
      prawidłowego świadczenia usług.
    </p>
  </Box>
)

export const BraContent = () => (
  <Box sx={{ p: ['24px', '32px'] }}>
    <h2 style={{ textAlign: 'center' }}>TERMS OF USE</h2>
    <p style={{ textAlign: 'center' }}>Last Update: April 19, 2024</p>
    <p>
      IMILE DELIVERY BRAZIL LTDA., registered under CNPJ: 47.173.294/0001-17, with address at
      Alameda Rio Negro No. 585, Suite 51, 5th floor, Alphaville, Barueri/SP, ZIP Code: 06470-040,
      Brazil ("iMile").
    </p>
    <p>
      On this page, you will find the standard Terms of Use and Conditions applicable to the iMile
      website. Please note that there may be specific terms and conditions applicable to certain
      countries
    </p>
    <h3>USE OF INTERACTIVE FEATURES</h3>
    <p>
      iMile provides interactive features, such as tracking, exclusively for specified purposes.
    </p>
    <h3>ACCURACY OF THE WEBSITE</h3>
    <p>
      This site may contain inaccuracies that will be corrected by iMile as identified. Information
      is updated regularly, but inaccuracies may occur between updates
    </p>
    <h3>VIRUSES</h3>
    <p>
      iMile endeavors to mitigate viruses on its site, but cannot guarantee complete removal.
      Caution is advised when downloading information
    </p>
    <h3>DISCLAIMER</h3>
    <p>
      Services, content, and information are provided "as is". iMile makes no warranties, express or
      implied, including warranties of fitness for a particular purpose.
    </p>
    <h3>LIMITATION OF LIABILITY</h3>
    <p>
      Under no circumstances will iMile be liable for indirect, consequential, or loss of profit
      damages related to the use of this site or its systems.
    </p>
    <h3>DISCLOSURE OF INFORMATION</h3>
    <p>
      Information provided to iMile is confidential and will not be disclosed except as necessary
      for the provision of services.
    </p>
  </Box>
)

export const NzlContent = () => (
  <Box sx={{ p: ['24px', '32px'] }}>
    <h2 style={{ textAlign: 'center' }}>Terms of Use for iMile New Zealand Website</h2>
    <p style={{ textAlign: 'center' }}>Last Update: August 20, 2024</p>
    <p>
      These Terms of Use (hereinafter as the Terms) apply to you personally and your use of the
      iMile New Zealand Website at <a href='https://www.imile.com/'>https://www.imile.com/</a>{' '}
      (hereinafter as the Website). This website is created and maintained by iMile Logistics
      Services Limited, a company incorporated in New Zealand whose registered address is at33c
      Mccrystal Avenue Eastern Beach Auckland 2012 and whose company number is 9210560 and NZBN
      9429052023507(hereinafter as iMile).
    </p>
    <p>
      By using the Website, you agree to these Terms. Your use of the Website and the
      functionalities provided to you within the Website shall only be admissible subject to your
      prior acceptance of the Terms. IF YOU DO NOT AGREE WITH ANY OF THE TERMS, PLEASE DO NOT USE
      THE WEBSITE.
    </p>
    <p>
      iMile reserves the right to change, modify, add or remove portions of these Terms at any time.
    </p>
    <p>
      You agree that iMile may, at its sole discretion and without prior notice to you, terminate
      your access to the Website and/or block your future access to the Website, if iMile determines
      that you have violated these Terms or other agreements or guidelines which may be associated
      with your use of the Website.
    </p>
    <h3>Software Content</h3>
    <p>
      iMile or its licensors own and retain all rights, including all applicable intellectual
      property rights, in all software on this Website, in both source and binary forms, sample
      code, APIs, SDKs, associated documentation, and other related materials (collectively,
      "Software Content").
    </p>
    <p>
      Unless you have obtained a relevant license through other agreement entered between you and
      iMile, nothing in these Terms grants any right or license to Software Content to you, and you
      shall not reverse engineer, decompile, disassemble, split, adapt, implant, or implement other
      derivative works on the Software Content.
    </p>
    <h3>Trademarks</h3>
    <p>
      All trademarks and logos displayed, mentioned or otherwise used in this Website are property
      of iMile or its licensors as stated if applicable. You are not permitted to use any of these
      trademarks or logos in any way without explicit prior written permission from iMile or such
      licensors as applicable.
    </p>
    <h3>Privacy</h3>
    <p>
      iMile will only use your personal data in accordance with iMile's{' '}
      <a onClick={() => window.open('/privacy-policy')}>Privacy Policy</a>.
    </p>
    <h3>Copyright</h3>
    <p>
      All contents on this Website are copyrighted by iMile or applicable licensors unless otherwise
      stated. Without prior written consent of iMile or other related parties, any content on the
      Website shall not be reproduced, modified, promoted, distributed, photocopied, played,
      disassembled, reverse engineered, decompiled, linked or transmitted with hyperlinks, loaded
      into other servers in "mirroring method", stored in information retrieval system, or otherwise
      used for any other purpose by any person by any means.
    </p>
    <h3>Website Content</h3>
    <p>
      The Website Contents include but are not limited to text, images, data, web pages, and links
      offered on or through the Website. This Website and its contents are provided for your
      convenience only. Although iMile has attempted to provide accurate information on this
      Website, it assumes no obligation or responsibility whatsoever regarding the accuracy and
      completeness of the information. The Website Contents may contain inadvertent inaccuracies or
      typographical errors. These will be corrected at iMile's discretion, as they are found.
    </p>
    <h3>Links to Third Parties</h3>
    <p>
      Although links to third party websites may be contained in this Website for your convenience,
      iMile shall not be responsible for any content of any such websites. You might need to review
      and agree to applicable rules of use when using such websites. In addition, a link to
      third-party website does not imply that iMile endorses the site or the products or services
      referenced therein.
    </p>
    <h3>Viruses</h3>
    <p>
      iMile makes all reasonable attempts to exclude viruses from these web pages, but it cannot
      ensure this exclusion, and no liability is accepted for viruses. Please take all appropriate
      safeguards before downloading information from these web pages.
    </p>
    <h3>Disclaimer of Warranties</h3>
    <p>
      All information provided on this website is provided on an "as is" basis without warranties,
      guarantees or representations of any kind. iMile hereby expressly disclaims, to the fullest
      extent permitted by law, all express, implied, statutory or other warranties, guarantees or
      representations, including but not limited to, the implied warranties of merchantability,
      fitness for a particular purpose, or non-infringement of third-party rights.
    </p>
    <h3>Limitation of Liability</h3>
    <p>
      To the extent permitted by law, neither iMile nor any of its affiliates, subsidiaries,
      directors, agents, employees or other representatives shall be liable for any direct,
      indirect, special, incidental, consequential, punitive, and/or exemplary damages including
      without limitation, loss of profits or revenues, loss of data, and/or loss of business, in
      connection with this Website or the use of or inability to use this Website or reliance on the
      contents contained herein, even if iMile is advised of the possibility of such damages. Unless
      otherwise agreed in writing by iMile in any applicable contract, to the extent permitted by
      the applicable law, iMile shall not be liable for the materials of this Website, regardless of
      the reason for the liability or the tort theory it is based on.
    </p>
    <h3>Disclosure of Information</h3>
    <p>
      All information provided to iMile by visitors to these web pages is considered to be
      confidential and will not be disclosed by iMile to any third party except as may be required
      for the provision of the services.
    </p>
  </Box>
)

export const EspContent = () => (
  <Box sx={{ p: ['24px', '32px'] }}>
    <h2 style={{ textAlign: 'center' }}>Términos de Uso del Sitio Web de iMile España</h2>
    <p style={{ textAlign: 'center' }}>Última actualización: 22 de octubre de 2024</p>
    <p>
      Estos Términos de Uso (en adelante, los "Términos") se aplican a ti personalmente y a tu uso
      del Sitio Web de iMile España en{' '}
      <a href='https://www.imile.com/es-MX/'>https://www.imile.com/es-MX/</a> (en adelante, el
      "Sitio Web"). Este sitio web es creado y mantenido por iMile Logistics & Transportation, S.L.,
      una empresa constituida en España, cuya dirección registrada es calle Goya, 21, 1D, 28001
      Madrid, España, y cuyo número de identificación fiscal es B55469589 (en adelante, "iMile"). Al
      usar el Sitio Web, aceptas estos Términos. Tu uso del Sitio Web y las funcionalidades que se
      te proporcionan dentro del Sitio Web solo serán admisibles con tu aceptación previa de los
      Términos. SI NO ESTÁS DE ACUERDO CON CUALQUIERA DE LOS TÉRMINOS, POR FAVOR NO USES EL SITIO
      WEB.
    </p>
    <p>
      iMile se reserva el derecho de cambiar, modificar, añadir o eliminar partes de estos Términos
      en cualquier momento. Aceptas que iMile puede, a su exclusivo criterio y sin previo aviso,
      terminar tu acceso al Sitio Web y/o bloquear tu acceso futuro al Sitio Web, si iMile determina
      que has violado estos Términos o otros acuerdos o directrices que puedan estar asociados con
      tu uso del Sitio Web.
    </p>
    <h3>Contenido de Software</h3>
    <p>
      iMile o sus licenciantes son los propietarios y mantienen todos los derechos, incluidos todos
      los derechos de propiedad intelectual aplicables, en todo el software de este Sitio Web, en
      ambas formas, fuente y binaria, código de muestra, API, SDK, documentación asociada y otros
      materiales relacionados (colectivamente, "Contenido de Software"). A menos que hayas obtenido
      una licencia relevante a través de otro acuerdo celebrado entre tú e iMile, nada en estos
      Términos te otorga ningún derecho o licencia sobre el Contenido de Software, y no deberás
      descompilar, desensamblar, dividir, adaptar, implantar o implementar otros trabajos derivados
      sobre el Contenido de Software.
    </p>
    <h3>Marcas Registradas</h3>
    <p>
      Todas las marcas y logotipos que se muestran mencionan o utilizan de otro modo en este Sitio
      Web son propiedad de iMile o de sus licenciantes, según corresponda. No se te permite utilizar
      ninguna de estas marcas o logotipos de ninguna manera sin el permiso previo y por escrito de
      iMile o de dichos licenciantes, según corresponda.
    </p>
    <h3>Privacidad</h3>
    <p>
      iMile solo utilizará tus datos personales de acuerdo con la Política de Privacidad de iMile.
    </p>
    <h3>Derechos de Autor</h3>
    <p>
      Todos los contenidos de este Sitio Web están protegidos por derechos de autor de iMile o de
      licenciantes aplicables, a menos que se indique lo contrario. Sin el consentimiento previo por
      escrito de iMile o de otras partes relacionadas, cualquier contenido en el Sitio Web no deberá
      ser reproducido, modificado, promovido, distribuido, fotocopiado, reproducido, desensamblado,
      descompilado, enlazado o transmitido con hiperenlaces, cargado en otros servidores de "método
      de espejado", almacenado en sistemas de recuperación de información, o utilizado de cualquier
      otro modo para cualquier otro propósito por cualquier persona y por cualquier medio.
    </p>
    <h3>Contenido del Sitio Web</h3>
    <p>
      Los Contenidos del Sitio Web incluyen, pero no se limitan a, texto, imágenes, datos, páginas
      web y enlaces ofrecidos en o a través del Sitio Web. Este Sitio Web y su contenido se
      proporcionan solo para tu conveniencia. Aunque iMile ha intentado proporcionar información
      precisa en este Sitio Web, no asume ninguna obligación o responsabilidad en absoluto respecto
      a la precisión y exhaustividad de la información. Los Contenidos del Sitio Web pueden contener
      inexactitudes involuntarias o errores tipográficos. Estos serán corregidos a discreción de
      iMile, a medida que se encuentren.
    </p>
    <h3>Enlaces a Terceros</h3>
    <p>
      Aunque el Sitio Web puede contener enlaces a sitios web de terceros para tu conveniencia,
      iMile no será responsable por ningún contenido de dichos sitios. Es posible que necesites
      revisar y aceptar las reglas de uso aplicables al utilizar tales sitios. Además, un enlace a
      un sitio web de terceros no implica que iMile respalde el sitio o los productos o servicios
      referenciados en él.
    </p>
    <h3>Virus</h3>
    <p>
      iMile hace todos los intentos razonables para excluir virus de estas páginas web, pero no
      puede garantizar esta exclusión, y no se acepta ninguna responsabilidad por virus. Por favor,
      toma todas las precauciones adecuadas antes de descargar información de estas páginas web.
    </p>
    <h3>Descargo de Responsabilidades</h3>
    <p>
      Toda la información proporcionada en este sitio web se ofrece "tal como está", sin garantías,
      garantías ni representaciones de ningún tipo. iMile por la presente renuncia expresamente, en
      la máxima medida permitida por la ley, a todas las garantías, garantías o representaciones
      expresas, implícitas, estatutarias u otras, incluidas, entre otras, las garantías implícitas
      de comercialización, adecuación para un propósito particular o no infracción de derechos de
      terceros.
    </p>
    <h3>Limitación de Responsabilidad</h3>
    <p>
      En la medida permitida por la ley, ni iMile ni ninguno de sus afiliados, subsidiarias,
      directores, agentes, empleados u otros representantes serán responsables de daños directos,
      indirectos, especiales, incidentales, consecuenciales, punitivos y/o ejemplares, incluidos,
      sin limitación, pérdida de ganancias o ingresos, pérdida de datos y/o pérdida de negocio, en
      relación con este Sitio Web o el uso o la imposibilidad de usar este Sitio Web o la
      dependencia de los contenidos aquí contenidos, incluso si iMile ha sido informado de la
      posibilidad de tales daños. A menos que se acuerde lo contrario por escrito por iMile en
      cualquier contrato aplicable, en la medida permitida por la ley aplicable, iMile no será
      responsable por los materiales de este Sitio Web, independientemente de la razón de la
      responsabilidad o la teoría de agravio en la que se base.
    </p>
    <h3>Divulgación de Información</h3>
    <p>
      Toda la información proporcionada a iMile por visitantes a estas páginas web se considera
      confidencial y no será divulgada por iMile a ningún tercero, excepto cuando sea necesario para
      la prestación de los servicios.
    </p>
  </Box>
)
